<template>
  <span class="WavPlayer clickable icon">
    <img
      ref="button"
      title="mängi klippi"
      src="../assets/icons/media-play.svg"
      v-on:click="playpause()"
    />
  </span>
</template>

<script>
export default {
  name: "WavPlayer",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  methods: {
    play() {
      try {
        this.audio.play();
        this.$refs.button.src = require("../assets/icons/media-pause.svg");
        this.$refs.button.title = "pausi klipp";
      } catch (err) {
        console.log("Audio error", err);
      }
    },
    // pause audio
    pause() {
      console.log("pause");
      try {
        this.audio.pause();
        this.$refs.button.src = require("../assets/icons/media-play.svg");
        this.$refs.button.title = "mängi klippi";
      } catch (err) {
        console.log("Audio error", err);
      }
    },
    // put audio on start position and pause
    ended() {
      this.audio.currentTime = 0;
      this.pause();
    },
    // toggle audio
    playpause() {
      this.button = this.$refs.button;
      if (!this.audio) {
        this.audio = new Audio(this.src);
        this.audio.load();
        this.audio.onended = () => {
          this.ended();
        };
        // this.audio.loop = true; // loop audio until pause
      }
      if (this.audio.paused) {
        this.play();
      } else {
        this.pause();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
