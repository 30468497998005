<template>
  <div class="Error">
    <h2>
      <img
        alt="Veateade"
        src="../assets/icons/warning.svg"
        class="inline"
      />Viga
    </h2>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "Error-Message",
  props: {
    text: {
      type: Text,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Error {
  margin: 2em auto;
  width: 300px;
  border: 3px solid lightslategrey;
}
</style>
