<template>
  <div class="Header">
    <img alt="Miski logo" src="../assets/logo.png" class="logo" />
    <h1>EKSKFK otsing</h1>
  </div>
</template>

<script>
export default {
  name: "SiteHeader",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
