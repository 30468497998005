<template>
  <div id="app">
    <MenuPane />
    <SiteHeader />
    <SearchBar query_date="" v-on:keyup.enter="searchHandler" />
    <ErrorMessage
      v-if="isFetchingErrors"
      text="Server ei tundu vastavat, proovi uuesti või teavita veebihaldajat."
    />
    <h2 v-if="isFetchingResults">
      <img src="./assets/icons/sync.svg" class="rotating" /> Otsimine...
    </h2>
    <ResultsList v-else v-bind:resultsList="resultsList" />
  </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import SearchBar from "./components/SearchBar.vue";
import ResultsList from "./components/ResultsList.vue";
import MenuPane from "./components/MenuPane.vue";
import ErrorMessage from "./components/ErrorMessage.vue";

export default {
  name: "App",
  components: {
    SiteHeader,
    SearchBar,
    ResultsList,
    MenuPane,
    ErrorMessage,
  },
  methods: {
    searchHandler() {
      // all the search is handled in fetchResults
      this.$store.dispatch("fetchResults");
    },

    // Saves the state as an historical state in the browser with url
    saveHistoricalState() {
      this.$store.commit("saveHistoricalState");
    },

    // Load a saved historical state
    popHistoricalState(history) {
      // is there a state saved in history or is it perhaps the start page?
      if (history.state) {
        this.$store.commit("setStateFromKoralQuery", history.state.koralQuery);
        this.$store.commit(
          "setResultsList",
          JSON.parse(history.state.resultsList),
        );
      } else {
        // if there is no saved history state, we'll try loading from URL
        // TODO this doesn't seem to work properly
        // TODO the results list should also be cleared
        this.loadStateFromUrl();
      }
    },

    // Loads a (partial) state from the Url
    loadStateFromUrl() {
      let urlQueryIndex = window.location.href.indexOf("?");
      if (urlQueryIndex === -1) {
        return false;
      } else {
        let koralQuery = JSON.parse(
          decodeURIComponent(window.location.href.substring(urlQueryIndex + 1)),
        );
        this.$store.commit("setStateFromKoralQuery", koralQuery);
        this.$store.dispatch("fetchResults");
        return true;
      }
    },
  },
  data: function () {
    return {};
  },
  computed: {
    resultsList() {
      return this.$store.state.resultsList;
    },
    getOldApiSearchUrl() {
      return this.$store.getters.getOldApiSearchUrl;
    },
    isFetchingResults() {
      return this.$store.state.isFetchingResults;
    },
    isFetchingErrors() {
      return this.$store.state.isFetchingErrors;
    },
  },
  created() {
    // Attach onpopstate event handler
    window.onpopstate = this.popHistoricalState;
    // Set the title TODO use html for  default text and implement FLT one day
    window.document.title = "EKSKFK avaleht";
    // Is this a saved search from the URL?
    this.loadStateFromUrl();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.icon {
  position: relative;
  bottom: -0.3em;
}

.inline {
  vertical-align: -0.125em;
}

.clickable {
  cursor: pointer;
}

a:link {
  color: midnightblue;
  text-decoration: none;
}

a:visited {
  color: darkslateblue;
}

a:hover {
  color: midnightblue;
  text-decoration: underline;
}

a:active {
  color: darkslateblue;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.rotating {
  animation: rotating 5s linear infinite;
}
</style>
