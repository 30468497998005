<template>
  <div class="SearchBarItem" id="search-bar">
    <SearchBarItem
      v-for="filter in filters"
      v-bind:key="filter.id"
      v-bind:filter="filter"
      v-on:remove="removeFilter"
      v-on:keydown:enter="initiateSearch"
    />
    <button v-on:click="addFilter">Lisa kriteerium</button>
    <div class="SearchBarCorpora">
      <input type="checkbox" id="skk0" value="skk0" v-model="checkedCorpora" />
      <label for="skk0">dialoogid</label>
      <input type="checkbox" id="skk1" value="skk1" v-model="checkedCorpora" />
      <label for="skk1">monoloogid</label>
      <input type="checkbox" id="skk2" value="skk2" v-model="checkedCorpora" />
      <label for="skk2">välitööd</label>
      <input type="checkbox" id="skk3" value="skk3" v-model="checkedCorpora" />
      <label for="skk3">trialoogid</label>
    </div>
    <div class="SearchBarSelectAmountOfResults">
      <input type="radio" id="ten" value="10" v-model="limit" />
      <label for="ten">10</label>
      <input type="radio" id="twenty" value="20" v-model="limit" />
      <label for="twenty">20</label>
      <input type="radio" id="thirty" value="30" v-model="limit" />
      <label for="thirty">30</label>
      <input type="radio" id="fifty" value="50" v-model="limit" />
      <label for="fifty">50</label>
      <input type="radio" id="hundred" value="100" v-model="limit" />
      <label for="hundred">100</label>
      <input type="radio" id="twohundred" value="200" v-model="limit" />
      <label for="twohundred">200</label>
    </div>
    <button v-on:click="initiateSearch">Otsi</button>
  </div>
</template>

<script>
import SearchBarItem from "./SearchBarItem.vue";
// import SearchBarCorpora from './SearchBarCorpora.vue'
// import SearchBarSelectAmountOfResults from './SearchBarSelectAmountOfResults.vue'

export default {
  name: "SearchBar",
  components: {
    SearchBarItem,
  },
  props: {},
  computed: {
    filters() {
      return this.$store.state.filters;
    },
    checkedCorpora: {
      get: function () {
        return this.$store.state.checkedCorpora;
      },
      set: function (allChecked) {
        this.$store.commit("setCheckedCorpora", allChecked);
      },
    },
    limit: {
      get: function () {
        return this.$store.state.limit;
      },
      set: function (newLimit) {
        this.$store.commit("setLimit", newLimit);
      },
    },
    parentFunction() {
      console.log("parentFunction filters", this.$store.state.filters);
      return this.$store.state.filters;
    },
  },
  methods: {
    // Adds a search filter to the search bar for narrowing down the search
    addFilter() {
      this.$store.commit("addFilter", {
        left: "word",
        operator: "startswith",
        right: "",
      });
    },
    // Removes a filter from the search bar
    removeFilter(idToRemove) {
      this.$store.commit("removeFilter", idToRemove);
    },
    // Initiates a search by creating a search url and emitting an 'search' event
    initiateSearch() {
      this.$store.dispatch("fetchResults");
    },

    // Get a KoralQuery object for the current search
    getCurrentSearchAsKoralQuery() {
      console.log("getCurrentSearchAsKoralQuery");
      return this.$store.getters.getCurrentSearchAsKoralQuery;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
