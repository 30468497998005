<template>
  <div v-if="resultsExist" class="ResultsList">
    <div class="ResultsListInfo">
      {{ leitiArvTulemusi }}.
      <div style="display: inline" v-if="resultsList.result.results.length">
        Kuvatakse {{ resultsList.result.results.length }}
        <button v-on:click="initiateDownload" style="margin-left: 15px">
          Laadi alla valitud
        </button>
        <button v-on:click="selectAllAndDownload" style="margin-left: 15px">
          Vali kõik ja laadi alla
        </button>
      </div>
    </div>

    <form name="result_form" id="result_form">
      <TextGrid
        v-for="(textgrid, textgridId) in resultsList.result.results"
        v-bind:key="textgridId"
        v-bind:textgrid="textgrid"
      />
    </form>
  </div>
  <div v-else class="ResultsList">
    Viga.
    {{ getErrorMessage }}
  </div>
</template>

<script>
import TextGrid from "./TextGrid.vue";

export default {
  name: "ResultsList",
  components: { TextGrid },
  props: ["resultsList"],
  data: function () {
    return {};
  },
  methods: {
    initiateDownload() {
      this.$store.dispatch("fetchZip");
    },
    selectAll() {
      let get = document.getElementsByName("download[]");
      for (var i = 0; i < get.length; i++) {
        get[i].checked = true;
      }
    },
    selectAllAndDownload() {
      this.selectAll();
      this.initiateDownload();
    },
  },

  computed: {
    resultsExist() {
      return this.resultsList.result !== undefined;
    },
    getErrorMessage() {
      return this.$store.state.ErrorMessage;
    },
    leitiArvTulemusi() {
      let totalResults = this.resultsList.result.totalResults || undefined;
      let morphologicalSentence;
      if (
        totalResults === undefined &&
        this.$store.state.isFetchingErrors === true
      ) {
        morphologicalSentence =
          "Lehe laadimisel tekkis viga. Kui sa testid lehte, siis vea väljundi leiad konsoolist.";
      } else if (
        totalResults === undefined &&
        this.$store.state.fetchCompleted === false
      ) {
        morphologicalSentence = "";
      } else if (
        totalResults === undefined &&
        this.$store.state.fetchCompleted === true
      ) {
        morphologicalSentence = "Ei leitud ühtegi tulemust.";
      } else if (totalResults === 1) {
        morphologicalSentence = "Leiti ainult üks tulemus.";
      } else {
        morphologicalSentence = "Leiti kokku " + totalResults + " tulemust";
      }
      return morphologicalSentence;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ResultsList {
  margin-top: 3em;
}
.ResultsListInfo {
  margin-bottom: 1.5em;
}
</style>
