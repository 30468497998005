<template>
  <div class="SearchBarItem">
    <select
      id="search-layer"
      name="search-layer"
      :value="filter.left"
      @change="updateInput($event, 'left')"
    >
      <option value="word">sõna</option>
      <option value="sampa">häälikud</option>
      <option value="cv">CV</option>
      <option value="morph">morfoloogia</option>
    </select>
    <select
      id="search-operator"
      name="search-operator"
      :value="filter.operator"
      @change="updateInput($event, 'operator')"
    >
      <option value="startswith">algab</option>
      <option value="endswith">lõppeb</option>
      <option value="contains">sisaldab</option>
      <option value="is">on</option>
      <option value="isnot">ei ole</option>
    </select>
    <input
      type="text"
      placeholder="kriteerium"
      :value="filter.right"
      @input="updateInput($event, 'right')"
      v-bind="$attrs"
    />
    <img
      v-if="hasMoreThanOneFilter"
      v-on:click="$emit('remove', filter.id)"
      title="tühista kriteerium"
      src="../assets/icons/x-circle.svg"
      class="clickable icon"
    />
  </div>
</template>

<script>
export default {
  name: "SearchBarItem",
  props: {
    filter: {
      type: Object,
      required: true,
      // @TODO: add default value https://vuejs.org/v2/guide/components-props.html#Prop-Validation
      default(rawProps) {
        console.log(rawProps);
        return {
          right: "",
          operator: "",
          left: "",
        };
      },
    },
  },
  computed: {
    hasMoreThanOneFilter: function () {
      return (
        this.$store.getters.getCurrentSearchAsKoralQuery.query.operands.length >
        1
      );
    },
  },
  methods: {
    updateInput(event, propertyName) {
      // it's a workaround for migration from Vue2 to Vue3
      // since $listeners are deprecated
      // find correct filter by id
      let index = this.$store.state.filters
        .map(function (e) {
          return e.id;
        })
        .indexOf(this.filter.id);
      this.$store.state.filters[index][propertyName] = event.target.value;
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
