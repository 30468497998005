<template>
  <div class="Textgrid textgrid">
    <div class="textgrid-item">
      <div class="textgrid-item-name">
        <b>{{ itemNameTranslation(textgrid.fields["type"]) }}</b>
      </div>
      <div
        class="textgrid-interval"
        v-bind:style="{ width: getIntervalWidthPx({ xmax: 2, xmin: 0 }) }"
      >
        &nbsp;
      </div>
    </div>
    <div
      class="textgrid-item"
      v-for="(item, itemName) in textgrid.data"
      v-bind:key="itemName"
      v-bind:item="item"
      v-bind:itemName="itemName"
    >
      <div class="textgrid-item-name">{{ itemNameTranslation(itemName) }}</div>
      <div
        class="textgrid-interval"
        v-for="(interval, intervalId) in item"
        v-bind:key="intervalId"
        v-bind:interval="interval"
        v-bind:style="{ width: getIntervalWidthPx(interval) }"
        v-bind:class="{
          'empty-interval': hasText(interval),
          'no-width': hasNoWidth(interval),
        }"
        v-bind:title="getIntervalAltText(interval)"
      >
        {{ interval.text }}
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        v-bind:id="'checkbox' + textgrid.fields['result number']"
        name="download[]"
        v-bind:value="textgrid.fields['result number']"
      />
      <b style="margin-right: 10px"
        ><label v-bind:for="'checkbox' + textgrid.fields['result number']"
          >Tulemus {{ textgrid.fields["result number"] }}</label
        ></b
      >
      <WavPlayer v-bind:src="apiurl + textgrid['fields']['wav file']" />mängi
      helilõiku
      <a v-bind:href="apiurl + textgrid['fields']['wav file']" class="ml-10"
        ><img
          title="laadi alla helilõik"
          src="../assets/icons/music-note.svg"
          class="clickable icon"
        />
        laadi alla helilõik</a
      >
      <a
        v-bind:href="apiurl + textgrid['fields']['textgrid file']"
        class="ml-10"
        ><img
          title="laadi alla TextGrid"
          src="../assets/icons/line-style.svg"
          class="clickable icon"
        />
        laadi alla TextGrid</a
      >
    </div>
  </div>
</template>

<script>
import WavPlayer from "./WavPlayer.vue";

export default {
  name: "TextGrid",
  components: {
    WavPlayer,
  },
  props: {
    textgrid: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      data: this.textgrid,
      segmentLength: 2, // search results are 2 second segments
      // segmentWidth: 800, // search results display as 800px wide
      //apiurl: "/api/",
      apiurl: process.env.VUE_APP_API_ROOT,
    };
  },
  methods: {
    itemNameTranslation(name) {
      let translationFor = {
        SKK0: "dialoog",
        SKK1: "monoloog",
        SKK2: "välitöö",
        SKK3: "trialoog",
        word: "sõnad",
        cv: "cv",
        sampa: "häälikud",
        syllable: "silbid",
        morph: "morfoloogia",
        phonation: "häälelaad",
        utterance: "lausungid",
        other: "muu",
        foot: "taktid",
        paralinguistic: "paralingvistiline",
        TP: "tp",
        ip: "IP-piirid",
        respiration: "resp",
        "": "IP-piirid",
      };
      return translationFor[name] || name;
    },
    getIntervalLength(interval) {
      return interval.xmax - interval.xmin;
    },
    getIntervalWidthPx(interval) {
      let width = this.getIntervalLength(interval) * this.timeAsPixels - 1; // -1 is for css border width
      if (width < 0) width = 0;
      return width + "px";
    },
    getIntervalAltText(interval) {
      let length = this.getIntervalLength(interval).toFixed(2) + "ms";
      return interval.text + " (" + length + ")";
    },
    hasText(interval) {
      return interval.text === "";
    },
    hasNoWidth(interval) {
      return this.getIntervalWidthPx(interval) === "0px";
    },
  },
  computed: {
    timeAsPixels() {
      return this.$store.state.textgridDisplayWidth / this.segmentLength;
    },
    segmentWidth() {
      return this.$store.state.textgridDisplayWidth;
    },
  },
};
</script>

<style scoped>
.textgrid {
  margin-bottom: 50px;
  min-width: max-content;
}

.textgrid-item {
  display: block;
  position: relative;
  height: 1.8em;
  line-height: 1.8em;
  font-family: sans-serif;
  font-size: 14px;
}

.textgrid-item-name {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  background-color: lightblue;
  width: 93px;
  height: 100%;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 0px 1px;
  position: relative;
  text-align: right;
  padding-right: 0.5em;
}

.textgrid-interval {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  height: 100%;
  text-align: center;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 0px 0px;
  background-color: mintcream;
}

.empty-interval {
  background-color: white;
}

.no-width {
  border-width: 0px;
}

.ml-10 {
  margin-right: 10px;
}
</style>
