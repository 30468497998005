<template>
  <div class="MenuPane">
    <div class="TopIconMenuL">
      <img
        v-on:click="toggleInfoVisibility"
        src="../assets/icons/hamburger-menu.svg"
        class="clickable"
      />
    </div>
    <div v-if="!isInfoCollapsed" class="SettingsFrameL">
      <h2>Info</h2>
      <div>
        <a href="ekskfk_info.html">Korpuse tutvustus</a>
      </div>
      <div>
        <a href="ekskfk_margendus.html">Märgenduspõhimõtted</a>
      </div>
      <div>
        <a href="ekskfk_margendamise_juhend.html">Märgendamise juhend</a>
      </div>
      <div>
        <a href="ekskfk_info_eng.html">About the corpus IN ENGLISH</a>
      </div>
    </div>
    <div class="TopIconMenu">
      <img
        v-on:click="toggleHelpVisibility"
        src="../assets/icons/info.svg"
        class="clickable"
      /><img
        v-on:click="toggleSettingsVisibility"
        src="../assets/icons/settings.svg"
        class="clickable"
      />
    </div>
    <div v-if="!isSettingsCollapsed" class="SettingsFrame">
      <h2>Seadistamine</h2>
      <div class="SettingsSection">
        <h3>Kuvamine</h3>
        TextGrid:
        <img
          v-on:click="displayTextGridsMoreWide"
          title="laienda kuvamist"
          src="../assets/icons/zoom-in.svg"
          class="clickable icon"
        />
        <img
          v-on:click="displayTextGridsMoreNarrow"
          title="ahenda kuvamist"
          src="../assets/icons/zoom-out.svg"
          class="clickable icon"
        />
      </div>
      <div class="empty-filler"></div>
    </div>
    <div v-if="!isHelpCollapsed" class="SettingsFrame">
      <h2>Otsingu kasutamisjuhised</h2>

      <p>Otsing toimub ühe sõnatasandi segmendi piires. Otsida saab kas</p>
      <ul>
        <li>sõna ortograafilist kuju (nt <i>midagi</i>),</li>
        <li>
          hääldust, mis on märgitud SAMPA transkriptsioonis (nt
          <i>mit_vAk_vi</i>),
        </li>
        <li>
          häälikustruktuuri, kus konsonandile vastab C ja vokaalile V (nt
          <i>CVCVCV</i>), või
        </li>
        <li>morfoloogilist infot (nt <i>miski+dagi //_P_ sg p, //</i>).</li>
      </ul>

      <p>
        Otsisõna võib kirjeldada kas kogu sõna või ainult sõnaosa. Valikud on:
      </p>
      <ul>
        <li>
          <b>algab</b> – sõna algab otsisõnaga, aga lõpus võib olla veel midagi;
        </li>
        <li>
          <b>lõppeb</b> – sõna lõppeb otsisõnaga, aga sõna alguses võib olla
          veel midagi;
        </li>
        <li>
          <b>sisaldab</b> – sõna sisaldab otsisõna, aga nii alguses kui lõpus
          võib veel midagi olla;
        </li>
        <li><b>on</b> – otsitakse täpset vastet otsisõnale;</li>
        <li>
          <b>ei ole</b> – otsitakse kõike muud kui täpset vastet otsisõnale.
        </li>
      </ul>

      <p>
        Otsingu tingimusi saab ka kombineerida eri tasandite vahel, aga ainult
        ühe sõna piires. Täiendava otsingukriteeriumi lisamiseks on nupp "lisa
        kriteerium" Näiteks saab otsida sõna, mis ortograafias on
        <i>tegelikult</i> ja häälikutasandil on <i>tek_velt</i>.
      </p>

      <p>
        Otsisõna tõlgendatakse regulaaravaldisena ja sellega peab arvestama
        mõningate sümbolite puhul, mis võivad märgenduses esineda: sümbolitele +
        (liitsõna) ja { (SAMPA /ä/) tuleb ette panna längkriips. Aga lisaks
        avardab regulaaravaldiste kasutamine üldisi otsinguvõimalusi.
      </p>

      <p>Mõned regulaaravaldise süntaksi elemendid:</p>
      <ul>
        <li>
          <b>. (punkt)</b> tähistab ükskõik mis sümbolit. Nt <i>.a</i> vasteteks
          on <i>ma</i>, <i>sa</i>, <i>ta</i>, <i>ka</i> jne.
        </li>
        <li>
          <b>? (küsimärk)</b> tähistab eelneva sümboli kordumist null või üks
          korda. Nt <i>tal?</i> vasteteks on <i>ta</i> ja <i>tal</i>, aga mitte
          <i>tall</i>.
        </li>
        <li>
          <b>+ (pluss)</b> tähistab eelneva sümboli kordumist üks või enam
          korda. Nt <i>ma+</i> vasteteks on <i>ma</i> ja <i>maa</i>, aga mitte
          <i>m</i>.
        </li>
        <li>
          <b>* (tärn)</b> tähistab eelneva sümboli kordumist null või enam
          korda. Nt <i>sam*</i> vasteteks on <i>sa</i> ja <i>samm</i>, aga ka
          <i>sammmmmmmm</i>, kui selline sõna peaks esinema.
        </li>
        <li>
          <b>{x} (loogelised sulud)</b> tähistab eelneva sümboli kordumist x
          korda. Näiteks <i>a{2}</i> vasteks on <i>aa</i> .
        </li>
        <li>
          <b>{x,y}</b> tähistab eelneva sümboli kordumist x kuni y korda.
          Näiteks <i>a{1,2}</i> vasteteks on <i>a</i> ja <i>aa</i>.
        </li>
        <li>
          <b>[] (nurksulud)</b> üks nurksulgudes olevatest sümbolitest võib
          esineda. Nt <i>[vk]ana</i> vasteteks on <i>vana</i> ja <i>kana</i>.
        </li>
        <li>
          <b>\ (längkriips)</b> tõlgendab järgnevat sümbolit sümbolina, kui sama
          sümbol võiks muidu tähistada mingit regulaaravaldise süntaksi
          elementi. Seda peab kasutama siis, kui otsida liitsõna (Sümbol
          <i>+</i> tähistab muidu eelneva sümboli kordumist), nt
          <i>keele\+teadus</i> või SAMPA transkriptsiooni <i>ä</i> vastet
          <i>{</i>, nt <i>t\{nt_vAp</i>. Sümbol on eesti klaviatuuril
          klahvikombinatsioonis AltGr+.
        </li>
      </ul>

      <h2>Vastused</h2>

      <p>
        Päringule antakse korpusest leitud vastetena kahesekundiline lõik,
        milles otsitav sõna esineb. See lõik kuvatakse tabelina, mis matkib
        Praati TextGridi märgendust. Erinevad märgenduskihid on eraldi ridadel,
        iga segmendi pikkus vastab selle kestusele. Kui segmendi sisu ei mahu
        intervalli sisse hästi ära, näeb seda hiirega segmendi peale minnes,
        lisaks sellele segmendi kestust millisekundites.
      </p>

      <p>
        Otsingut saab teha eraldi korpuse kolmes alaosas: stuudios salvestatud
        dialoogid, loengusaalis salvestatud monoloogid ning välitööl salvestatud
        dialoogid. Tulemused esitatakse hajutatuna üle korpuse, et kõik vastused
        ei oleks järjest samast salvestusest. Maksimaalselt kuvatakse kuni 200
        vastust.
      </p>

      <p>
        Lisaks veebis kuvatavale on võimalik vastav helilõik (wav-fail) ja/või
        TextGrid alla laadida.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuPane",
  props: {
    settings: {
      type: Object,
    },
    isInitiallyCollapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      isSettingsCollapsed: this.isInitiallyCollapsed,
      isHelpCollapsed: this.isInitiallyCollapsed,
      isInfoCollapsed: this.isInitiallyCollapsed,
    };
  },
  methods: {
    toggleSettingsVisibility() {
      this.isSettingsCollapsed = !this.isSettingsCollapsed;
      this.isHelpCollapsed = true;
      this.isInfoCollapsed = true;
    },
    toggleHelpVisibility() {
      this.isHelpCollapsed = !this.isHelpCollapsed;
      this.isSettingsCollapsed = true;
      this.isInfoCollapsed = true;
    },
    toggleInfoVisibility() {
      this.isInfoCollapsed = !this.isInfoCollapsed;
      this.isSettingsCollapsed = true;
      this.isHelpCollapsed = true;
    },
    displayTextGridsMoreWide() {
      this.$store.commit("displayTextGridsMoreWide");
    },
    displayTextGridsMoreNarrow() {
      this.$store.commit("displayTextGridsMoreNarrow");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hide:rightmenupane {
  z-index: 1000;
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  text-align: left;
  width: 390px;
  overflow: auto;
}

.hide:leftmenupane {
  z-index: 1000;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  text-align: left;
  width: 390px;
  overflow: auto;
}

.SettingsFrame {
  z-index: 1000;
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0px;
  right: 0px;
  padding-left: 1.5em;
  padding-top: 1.5em;
  padding-right: 1.5em;
  border-left: 1px solid lightgrey;
  background-color: white;
  text-align: left;
  overflow: scroll;
}
.SettingsFrame em {
  z-index: 1000;
  letter-spacing: 0.05em;
  /* line-height: 1.3em; */
}

.SettingsFrameL {
  z-index: 1000;
  position: fixed;
  width: 300px;
  height: auto;
  top: 0px;
  left: 0px;
  padding-left: 1.5em;
  padding-top: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 1.5em;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  text-align: left;
}
.SettingsFrameL em {
  z-index: 1000;
  letter-spacing: 0.05em;
  /* line-height: 1.3em; */
}

.empty-filler {
  /* height: 100%; */
}

li {
  margin: 1em 0em 1em 1em;
}

.TopIconMenu {
  z-index: 1001;
  position: fixed;
  text-align: left;
  right: 3px;
  top: 3px;
  margin-right: 20px;
}
.TopIconMenuL {
  z-index: 1001;
  position: fixed;
  text-align: left;
  left: 3px;
  top: 3px;
}
</style>
